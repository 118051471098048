<template>
    <div>
        <h2 class="mb-1">Create new clinician</h2>
        <b-card>
            <b-tabs>
                <b-tabs
                    vertical
                    nav-wrapper-class="nav-vertical"
                >
                    <b-tab
                        active
                        title="Clinician Details"
                    >
                        <validation-observer ref="addClinicianRules">
                            <b-form
                                class="px-2"
                            >
                                <b-row class="mb-3">
                                <b-col md="8">
                                    <!-- Profile -->
                                    <h4>Profile</h4>
                                    <hr>
                                    <b-row>
                                        <b-col md="6">
                                            <!-- First Name -->
                                            <b-form-group
                                                label-for="First name"
                                            >
                                            <template v-slot:label>
                                                First name <span class="text-danger">*</span>
                                            </template>
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="First name"
                                                    rules="required"
                                                >
                                                    <b-form-input
                                                        id="full-name"
                                                        v-model="firstName"
                                                        trim
                                                        :state="errors.length > 0 ? false:null"
                                                        placeholder="First name"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <b-col md="6">
                                            <!-- Last Name -->
                                            <b-form-group
                                                label-for="Last name"
                                            >
                                            <template v-slot:label>
                                                Last name <span class="text-danger">*</span>
                                            </template>
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Last name"
                                                    rules="required"
                                                >
                                                    <b-form-input
                                                        id="full-name"
                                                        v-model="lastName"
                                                        trim
                                                        :state="errors.length > 0 ? false:null"
                                                        placeholder="Last name"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    
                                    <b-col md="6" class="pl-0">
                                        <!-- Agency -->
                                        <b-form-group
                                            label="Agency"
                                            label-for="agency"
                                        >
                                        <!-- <template v-slot:label>
                                            Agency <span class="text-danger">*</span>
                                        </template> -->
                                            <!-- <validation-provider
                                                #default="{ errors }"
                                                name="Agency"
                                                rules="required"
                                            > -->
                                                <b-form-input
                                                    v-model="agency"
                                                    trim
                                                    placeholder="Agency"
                                                />
                                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                            <!-- </validation-provider> -->
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="6" class="pl-0">
                                        <!-- Email -->
                                        <b-form-group
                                            label-for="email"
                                        >
                                        <template v-slot:label>
                                            Email <span class="text-danger">*</span>
                                        </template>
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Email"
                                                rules="required|email"
                                            >
                                                <b-form-input
                                                    id="email"
                                                    v-model="email"
                                                    :state="errors.length > 0 ? false:null"
                                                    trim
                                                    placeholder="Email"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    
                                    <b-col md="6" class="pl-0">
                                        <!-- County of Residence -->
                                        <validation-provider
                                            #default="{ errors }"
                                            name="County"
                                            rules="required"
                                        >
                                            <b-form-group
                                                label-for="County"
                                                :state="errors.length > 0 ? false:null"
                                            >
                                            <template v-slot:label>
                                                County <span class="text-danger">*</span>
                                            </template>
                                                <v-select
                                                    v-model="countryOfResidence"
                                                    placeholder="County"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsCountryOfResidence"
                                                />
                                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                    {{ errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    
                                    <b-row class="px-10">
                                        <b-col md="6">
                                            <!-- Region -->
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Region"
                                                rules="required"
                                            >
                                                <b-form-group
                                                    label-for="region"
                                                    :state="errors.length > 0 ? false:null"
                                                >
                                                <template v-slot:label>
                                                    Region <span class="text-danger">*</span>
                                                </template>
                                                    <v-select
                                                        v-model="region"
                                                        placeholder="Region"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        :options="optionsRegion"
                                                    />
                                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                        {{ errors[0] }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6" v-if="region === 'NYC'">
                                            <!-- Borough -->
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Borough"
                                                rules="required"
                                            >
                                                <b-form-group
                                                    label-for="borough"
                                                    :state="errors.length > 0 ? false:null"
                                                >
                                                <template v-slot:label>
                                                    Borough <span class="text-danger">*</span>
                                                </template>
                                                    <v-select
                                                        v-model="borough"
                                                        placeholder="Borough"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        :options="optionsBorough"
                                                    />
                                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                        {{ errors[0] }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                    </b-row>

                                    <b-row class="px-10">
                                        <b-col md="6">
                                            <!-- Program -->
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Program"
                                                rules="required"
                                            >
                                                <b-form-group
                                                    label-for="program"
                                                    :state="errors.length > 0 ? false:null"
                                                >
                                                <template v-slot:label>
                                                    Program <span class="text-danger">*</span>
                                                </template>
                                                    <v-select
                                                        v-model="program"
                                                        placeholder="Program"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        :options="optionsProgram"
                                                    />
                                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                        {{ errors[0] }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6" v-if="program === 'OASAS'">
                                            <!-- Program Type -->
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Program Type"
                                                rules="required"
                                            >
                                                <b-form-group
                                                    label-for="program-type"
                                                    :state="errors.length > 0 ? false:null"
                                                >
                                                <template v-slot:label>
                                                    Program Type <span class="text-danger">*</span>
                                                </template>
                                                    <v-select
                                                        v-model="programType"
                                                        placeholder="Program Type"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        :options="optionsProgramType"
                                                    />
                                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                        {{ errors[0] }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                    </b-row>
                                    
                                    <!-- Details -->
                                    <h4 class="mt-2">Details</h4>
                                    <hr>
                                    <b-row cols="12">
                                        <b-col md="6">
                                            <!-- License type -->
                                            <b-form-group
                                                label="License Type"
                                                label-for="licenseType"
                                            >
                                                <v-select
                                                    v-model="licenseType"
                                                    placeholder="License type"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsLicenseType"
                                                    multiple
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                            <!-- Licese number -->
                                            <b-form-group
                                                label="License Number"
                                                label-for="License Number"
                                            >
                                                <b-form-input
                                                    v-model="licenseNumber"
                                                    trim
                                                    placeholder="License number"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    
                                    <b-row cols="12">
                                        <b-col md="6">
                                            <!-- Insurance types accepted -->
                                            <b-form-group
                                                label="Insurance types accepted"
                                                label-for="Insurance types accepted"
                                            >
                                                <v-select
                                                    v-model="insuranceTypesAccepted"
                                                    placeholder="Insurance types accepted"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsInsuranceTypesAccepted"
                                                    multiple
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <!-- <b-col md="6" v-if="program === 'PGRC - Private Practitioner' || program === 'PGRC - Group Practice'">
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Accepting new clients"
                                                rules="required"
                                            >
                                                <b-form-group
                                                    label-for="Accepting new clients"
                                                    :state="errors.length > 0 ? false:null"
                                                >
                                                <template v-slot:label>
                                                    Accepting new clients <span class="text-danger">*</span>
                                                </template>
                                                    <v-select
                                                        v-model="acceptingNewClients"
                                                        placeholder="Accepting new clients"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        :options="optionsYesNo"
                                                    />
                                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                        {{ errors[0] }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col> -->
                                    </b-row>

                                    <b-row cols="12">
                                        <b-col md="6">
                                            <!-- Specialities -->
                                            <b-form-group
                                                label="Specialities"
                                                label-for="Specialities"
                                            >
                                                <v-select
                                                    v-model="specialities"
                                                    placeholder="Specialities"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    :options="optionsSpecialities"
                                                    multiple
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <!-- <b-col md="6" v-if="program === 'PGRC - Private Practitioner' || program === 'PGRC - Group Practice'">
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Accepting referrals outside of catchment"
                                                rules="required"
                                            >
                                                <b-form-group
                                                    label-for="Accepting referrals outside of catchment"
                                                    :state="errors.length > 0 ? false:null"
                                                >
                                                <template v-slot:label>
                                                    Accepting referrals outside of catchment <span class="text-danger">*</span>
                                                </template>
                                                    <v-select
                                                        v-model="acceptingReferralsOutsideOfCatchment"
                                                        placeholder="Accepting referrals outside of catchment"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        :options="optionsYesNo"
                                                    />
                                                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                                        {{ errors[0] }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col> -->
                                    </b-row>

                                    <b-col md="6" class="pl-0">
                                        <!-- Languages -->
                                        <b-form-group
                                            label="Languages"
                                            label-for="Languages"
                                        >
                                            <v-select
                                                v-model="languages"
                                                placeholder="Languages"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsLanguages"
                                                multiple
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="6" class="pl-0">
                                        <!-- Mailing address -->
                                        <b-form-group
                                            label="Mailing address"
                                            label-for="Mailing address"
                                        >
                                            <b-form-input
                                                v-model="mailingAddress"
                                                trim
                                                placeholder="Mailing address"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-row cols="12">
                                        <b-col md="4">
                                            <!-- City -->
                                            <b-form-group
                                                label="City"
                                                label-for="City"
                                            >
                                                <b-form-input
                                                    v-model="city"
                                                    trim
                                                    placeholder="City"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4">
                                            <!-- State -->
                                            <b-form-group
                                                label="State"
                                                label-for="State"
                                            >
                                                <b-form-input
                                                    v-model="state"
                                                    trim
                                                    placeholder="State"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4">
                                            <!-- zip -->
                                            <b-form-group
                                                label="ZIP"
                                                label-for="ZIP"
                                            >
                                                <b-form-input
                                                    v-model="zip"
                                                    trim
                                                    placeholder="ZIP"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-col md="6" class="pl-0">
                                        <!-- Phone -->
                                        <b-form-group
                                            label="Phone number"
                                            label-for="phone"
                                        >
                                            <b-form-input
                                                id="phone"
                                                v-model="contact"
                                                trim
                                                placeholder="Phone number"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <!-- Attributes -->
                                    <!-- <h4 class="mt-2">Attributes</h4>
                                    <hr>
                                    <b-col md="6" class="pl-0"> -->
                                        <!-- Attributes -->
                                        <!-- <b-form-group
                                            label="Attributes"
                                            label-for="Attributes"
                                        >
                                            <v-select
                                                v-model="attributes"
                                                placeholder="Attributes"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                :options="optionsAttributes"
                                            />
                                        </b-form-group>
                                    </b-col> -->

                                    <!-- Notes -->
                                    <h4 class="mt-2">Notes</h4>
                                    <hr>
                                    <b-col md="6" class="pl-0">
                                        <!-- Notes -->
                                        <b-form-group
                                            label="Notes"
                                            label-for="notes"
                                        >
                                            <b-form-textarea
                                                v-model="notes"
                                                placeholder="Notes"
                                                rows="3"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-col>


                                <b-col md="4">
                                    <!-- Attributes -->
                                    <h4>Attributes</h4>
                                    <hr>
                                    <b-form-checkbox
                                        v-model="providesRemoteAndOrOffSiteSessionsIfRequired"
                                        :value="true"
                                    >
                                    Provides remote and/or off-site sessions if required
                                    </b-form-checkbox>
                                </b-col>

                                    <!-- Form Actions -->
                                    <!-- <div class="w-100 mt-0" style="position: absolute !important; right: 0 !important;"> -->
                                    <!-- <b-row> -->
                                        <b-col md="10"></b-col>
                                        <b-col md="2">
                                            <b-button
                                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                variant="primary"
                                                class="mr-2"
                                                type="submit"
                                                @click.prevent="validationForm"
                                            >
                                            Save
                                            </b-button>
                                        </b-col>
                                    <!-- </b-row> -->
                                    <!-- </div> -->
                                
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </b-tab>
                </b-tabs>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BTabs, BTab, BCardText, BCard, BCol, BRow, BFormTextarea, BFormRadio,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import moment from 'moment'
import { mapActions } from 'vuex'
import { db } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            countries,

            // profile
            firstName: '',
            lastName: '',
            agency: '',
            email: '',
            countryOfResidence: '',
            optionsCountryOfResidence: [],
            region: '',
            optionsRegion: [],
            borough: '',
            optionsBorough: [
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island'
            ],
            program: '',
            optionsProgram: [],

            // program OASAS
            programType: '',
            optionsProgramType: [
                'Outpatient Treatment Services',
                'ATCs (OASAS run)',
                'Chemical Dependency Youth',
                'Crisis Services',
                'Inpatient Treatment Services',
                'Methadone Treatment',
                'Residential Treatment Services'
            ],

            // details
            licenseType: '',
            optionsLicenseType: [],
            licenseNumber: '',
            insuranceTypesAccepted: '',
            optionsInsuranceTypesAccepted: [],
            specialities: '',
            optionsSpecialities: [],
            languages: '',
            optionsLanguages: [],
            mailingAddress: '',
            city: '',
            state: '',
            zip: '',
            contact: '',
            acceptingNewClients: '',
            acceptingReferralsOutsideOfCatchment: '',
            optionsYesNo: [
                'Yes',
                'No'
            ],

            // Attributes
            attributes: '',
            optionsAttributes: [],
            providesRemoteAndOrOffSiteSessionsIfRequired: false,

            // notes
            notes: '',
            
            rules: {
                required: v => !!v || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                email: v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Email not valid.'
                },
            },
            activateDate: null,
            expirationDate: null,
            advancedTrainingCompletedDate: null,
        }
    },
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BCardText,
        BTabs,
        BTab,
        BCard,
        BCol,
        BRow,
        BFormTextarea,
        BFormRadio,
        BFormCheckbox,
        vSelect,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    methods: {
        ...mapActions(['createClinician']),
        validationForm() {
            this.$refs.addClinicianRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.submitUsuario()
                }
            })
        },
        submitUsuario() {
            let objectUsuario = {
                firstName: this.firstName,
                lastName: this.lastName,
                agency: this.agency,
                email: this.email,
                countryOfResidence: this.countryOfResidence,
                region: this.region,
                borough: this.borough,
                program: this.program,
                programType: this.programType,

                // details
                licenseType: this.licenseType,
                licenseNumber: this.licenseNumber,
                insuranceTypesAccepted: this.insuranceTypesAccepted,
                specialities: this.specialities,
                // acceptingNewClients: this.acceptingNewClients,
                // acceptingReferralsOutsideOfCatchment: this.acceptingReferralsOutsideOfCatchment,
                languages: this.languages,
                mailingAddress: this.mailingAddress,
                city: this.city,
                state: this.state,
                zip: this.zip,
                contact: this.contact,

                // Attributes
                attributes: this.attributes,
                providesRemoteAndOrOffSiteSessionsIfRequired: this.providesRemoteAndOrOffSiteSessionsIfRequired,

                // notes
                notes: this.notes,
                role: 'clinician',
                statusReferral: 'active',

                objectTracking: {
                    regionalCFE: '',
                    datePickers: [
                        {text: 'Application of Treatment Essentials Completed Date', name: 'twoDaysTrainingCompletedDate', value: null},
                        {text: 'CBT Primer Completed Date', name: 'webinarCBTCompletedDate', value: null},
                        {text: 'MI Primer Completed Date', name: 'webinarMICompletedDate', value: null},
                        {text: 'PG Treatment Specialist Completed Date', name: 'sixtyHourCourse', value: null},
                        {text: 'Site Visit Completed Date', name: 'collaborativeInterviewCompletedDate', value: null},
                        {text: 'Clinician Agreement Signed Date', name: 'MOUSignedDate', value: null},
                        {text: 'Malpractice Insurance Expiration Date', name: 'malpracticeInsuranceExpirationDate', value: null},
                        {text: 'License Expiration Date', name: 'licenseExpirationDate', value: null},
                        {text: 'Active Date', name: 'activeDate', value: this.activateDate},
                        {text: 'Expiration Date', name: 'expirationDate', value: this.expirationDate},
                        {text: 'Advanced Training Completed Date', name: 'advancedTrainingCompletedDate', value: null}
                    ],
                    
                    // notes
                    notes: '',
                    referenceOneReceived: false,
                    referenceTwoReceived: false,
                    referenceThreeReceived: false,
                    wNine: false,
                    directDeposit: false,
                }
            }
            // objectUsuario.objectTracking.datePickers[8].value = this.activateDate
            // objectUsuario.objectTracking.datePickers[9].value = this.expirationDate
            // console.log(objectUsuario,this.activateDate,this.expirationDate)
            this.createClinician(objectUsuario)
            this.$refs.addClinicianRules.reset()
            this.firstName = ''
            this.lastName = ''
            this.agency = ''
            this.email = ''
            this.countryOfResidence = '',
            this.region = ''
            this.borough = ''
            this.program = ''
            this.programType = ''

            // details
            this.licenseType = ''
            this.licenseNumber = ''
            this.insuranceTypesAccepted = ''
            this.specialities = ''
            this.acceptingNewClients = ''
            this.acceptingReferralsOutsideOfCatchment = ''
            this.languages = ''
            this.mailingAddress = ''
            this.city = ''
            this.state = ''
            this.zip = ''
            this.contact = ''

            // Attributes
            this.attributes = ''
            this.providesRemoteAndOrOffSiteSessionsIfRequired = false

            // notes
            this.notes = ''
            this.$refs.addClinicianRules.reset()
        }
    },
    created() {
        var val = new Date()
        var formatDateOrigin = moment(val,'MM/DD/YYYY').format('MM/DD/YYYY')
        var formatDate = moment(formatDateOrigin).format('MM/DD/YYYY')
        var expiredDate = moment(formatDate).add(12, 'months')
        this.activateDate = moment(val,'MM-DD-YYYY').format('MM-DD-YYYY')
        this.expirationDate = moment(expiredDate,'MM/DD/YYYY').format('MM-DD-YYYY')
        // console.log(this.activateDate,formatDate,this.expirationDate)

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegion = docTaxonomia.data().region
            this.optionsProgram = docTaxonomia.data().program
            this.optionsAttributes = docTaxonomia.data().clinicianAttributes

            // clinician details fields
            this.optionsLicenseType = docTaxonomia.data().licenseType
            this.optionsInsuranceTypesAccepted = docTaxonomia.data().insuranceTypesAcepted
            this.optionsSpecialities = docTaxonomia.data().specialities
            this.optionsLanguages = docTaxonomia.data().languages

            // tracking details fields
            // this.regionalCFEAssigned = docTaxonomia.data().regionalCFEAssigned
        })

        db.collection('taxonomies').doc('client').get()
        .then(docTaxonomia => {
            this.optionsCountryOfResidence = docTaxonomia.data().countryOfResidence
        })
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>